import React from 'react';
import PropTypes from 'prop-types';
import { ClosableBox } from '../../../molecules/ClosableBox';
import { Overlay } from '../../../atoms/Overlay';
import { Fade } from '../../../atoms/Fade';
import { ImageTakeover } from '../../../../ImageTakeover';

export const ContentTakeOverExpandedForImage = ({ imageEntity, closeCb }) => {
  return (
    <Fade show position="fixed" zIndex="999">
      <Overlay color="#000">
        <ClosableBox
          closeColor="#fff"
          closeCb={closeCb}
          closeIconPosition={{ top: 20, right: 30 }}
        >
          <ImageTakeover entity={imageEntity} />
        </ClosableBox>
      </Overlay>
    </Fade>
  );
};

ContentTakeOverExpandedForImage.propTypes = {
  imageEntity: PropTypes.objectOf(PropTypes.any).isRequired,
  closeCb: PropTypes.func.isRequired
};
